<template>
    <section>
        <modal ref="modalPedidoMinimo" titulo="Pedido Minimo" no-aceptar>
            <div class="row mx-0 justify-center">
                <img :src="url_imge" style="max-width:100%;height:288px;" />
                <div class="col-12 text-general text-center f-15 mt-3">
                    ¡Lo sentimos!
                </div>
                <div class="col-12 text-general text-center f-15 mt-3">
                    El pedido minimo debe ser de </br>
                    <b>{{ convertMoneyTendero(rta.pedido_minimo,$tienda.idm_moneda) }}</b>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
export default {
    data(){
        return {
            url_imge: '/img/modales/stop.svg',
            rta:{pedido_minimo:0}
        }
    },
    methods: {
        toggle(response){
            this.rta = response
            this.$refs.modalPedidoMinimo.toggle();
        },
    }
}
</script>
